import { render, staticRenderFns } from "./UserDisplay.vue?vue&type=template&id=0d0f96be&scoped=true&"
import script from "./UserDisplay.vue?vue&type=script&lang=js&"
export * from "./UserDisplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d0f96be",
  null
  
)

export default component.exports